import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getUserData } from '../utils/apiService';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));

  const fetchData = async (userId) => {
    try {
      const response = await getUserData(userId);
      setUser(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Benutzerdaten:', error);
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setIsLoggedIn(true);
      fetchData(decodedToken.id);
    } else {
      setIsLoggedIn(false);
      setUser(null);
    }
  }, [token]);

  // Methode zum Aktualisieren des Tokens und Auslösen des useEffect
  const updateToken = (newToken) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, setUser, updateToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // Verwendet HttpApi für das Laden von Übersetzungen
  .use(initReactI18next) // Initialisiert react-i18next
  .init({
    backend: {
      loadPath: 'https://api.clockwise-pro.com/translations/{{lng}}', // Stellt sicher, dass Ihr Server unter diesem Pfad die Übersetzungen bereitstellt
    },
    lng: 'de', // Standard-Sprache auf Deutsch gesetzt
    fallbackLng: 'de', // Fallback-Sprache auf Deutsch gesetzt
    interpolation: {
      escapeValue: false, // Für die Verwendung mit React
    },
    react: {
      useSuspense: false // Suspense wird nicht verwendet
    },
  });

export default i18n;


import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail } from '../../utils/apiService'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const VerifyEmail = () => {
  // Lokaler State, um den Ladezustand und Fehler zu verwalten
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Lokaler State, um den Dialog zu steuern
  const [open, setOpen] = useState(false);

  // Hooks für die Navigation und zum Zugriff auf die URL
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Extrahieren des Tokens aus dem Query-String der URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    // Verifizierungsprozess starten, wenn ein Token vorhanden ist
    if (token) {
      verifyEmail(token)
        .then(() => {
          setLoading(false);
          setOpen(true); // Dialog öffnen bei erfolgreicher Verifizierung
        })
        .catch((error) => {
          console.error('Fehler bei der E-Mail-Verifizierung:', error);
          setError(error);
          setLoading(false);
        });
    }
  }, [location, navigate]);

  // Schließt den Dialog und navigiert zur Home-Seite
  const handleClose = () => {
    setOpen(false);
    navigate('/'); // Navigieren zur Home-Seite
  };

  // Anzeige während des Ladens
  if (loading) return <div>Verifizierung läuft...</div>;
  // Anzeige bei einem Fehler
  if (error) return <div>Fehler bei der Verifizierung</div>;

  // Dialog-Anzeige für die erfolgreiche Verifizierung
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"E-Mail-Verifizierung"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          E-Mail erfolgreich verifiziert.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyEmail;

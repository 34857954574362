import React, { useState, Suspense, lazy } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/Routes/ProtectedRoute';
import theme from './theme/theme';
import './App.css';
import './components/helpers/i18next';
import Navbar from './components/Navbar/Navbar';
import VerifyEmail from './components/helpers/VerifyEmail';
import LanguageSelectionModal from './components/helpers/LanguageSelectionModal';

const Home = lazy(() => import('./views/Home'));
const More = lazy(() => import('./views/more'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const Profil = lazy(() => import('./views/Profil'));
const LoginPopup = lazy(() => import('./components/Popup/LoginPopup'));
const RegisterPopup = lazy(() => import('./components/Popup/RegisterPopup'));
const AdminView = lazy(() => import('./views/AdminView'));

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const openLoginPopup = () => setShowLogin(true);
  const openRegisterPopup = () => setShowRegister(true);
  const closePopup = () => {
    setShowLogin(false);
    setShowRegister(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LanguageSelectionModal />
        <Router>
          <Navbar onLogin={openLoginPopup} onRegister={openRegisterPopup} />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home onLogin={openLoginPopup} onRegister={openRegisterPopup} />} />
              <Route path="/more" element={<More />} />
              <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
              <Route path="/profil" element={<ProtectedRoute component={Profil} />} />
              <Route path="/admin" element={<ProtectedRoute component={AdminView} />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
            </Routes>
          </Suspense>
          <Suspense fallback={<div>Loading Popup...</div>}>
            <LoginPopup open={showLogin} onClose={closePopup} />
            <RegisterPopup open={showRegister} onClose={closePopup} />
          </Suspense>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
import axios from 'axios';

const API_BASE_URL = 'https://api.clockwise-pro.com';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const loginUser = async (email, passwort) => {
  return api.post('/auth/login', { email, passwort });
};

export const registerUser = async (email, passwort, vorname, nachname) => {
  return api.post('/auth/register', { 
    email, 
    passwort, 
    vorname, 
    nachname 
  });
};

export const getUserData = async (userId) => {
  return api.get(`/benutzer/${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const updateUserData = async (userId, userData) => {
  return api.put(`/benutzer/${userId}/update`, userData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const deleteUserData = async (userId) => {
  return api.delete(`/benutzer/${userId}/delete`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const getTranslations = async (languageCode) => {
  return api.get(`/translations/${languageCode}`);
};

export const getLanguages = async () => {
  return api.get('/translations/languages');
};

export const getSelectLanguageTranslations = async () => {
  return api.get('/translations/1/select_language');
};

export const verifyEmail = async (token) => {
  try {
    const response = await axios.get(`http://localhost:3000/verify-email?token=${token}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`Serverfehler: ${error.response.status}`);
    } else if (error.request) {
      throw new Error('Keine Antwort vom Server');
    } else {
      throw new Error('Fehler bei der Anfrage');
    }
  }
};

export const getAlleBenutzer = async () => {
  return api.get('/benutzer', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
};

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1e1985', // Beispiel für eine primäre Farbe
    },
    secondary: {
      main: '#19857b', // Beispiel für eine sekundäre Farbe
    },

    buttoncancel: {
      main: '#e30b0b',
    },

    buttonchange: {
      main: '#fa7f05',
    },

    buttondelete: {
      main: '#ff000',
    },

    buttonsave: {
      main: '#11f947',
    },

    backgroundform: {
      main: '#F5F5F5',
    }

  },

});

export default theme;

import React, { useContext, useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";

// Import der Icons
import homeIcon from "../img/home.svg";
import loginIcon from "../img/login.svg";
import logoutIcon from "../img/logout.svg";
import profilIcon from "../img/profil.svg";
import clockIcon from "../img/clock.svg";
import signInIcon from "../img/signin.svg";

const Navbar = ({ onLogin, onRegister }) => {
  const { isLoggedIn, setIsLoggedIn, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsAdmin(user && user.rollen_id === 1);
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false); // Korrektur: Verwende setIsLoggedIn aus dem Context
    navigate("/");
  };;

  const renderAdminButton = () => (
    isAdmin && (
      <Button
        color="inherit"
        onClick={() => navigate("/admin")}
        sx={{ marginRight: "10px", "&:hover": { backgroundColor: "lightgray" } }}
      >
        Admin
      </Button>
    )
  );

  const renderDesktopButtons = () => (
    <>
      <Button
        color="inherit"
        onClick={() => navigate("/")}
        startIcon={<img src={homeIcon} alt="Home" />}
        sx={{ marginRight: "10px", "&:hover": { backgroundColor: "lightgray" } }}
      >
        {t('home')}
      </Button>
      {!isLoggedIn && (
        <>
          <Button
            color="inherit"
            onClick={onLogin}
            startIcon={<img src={loginIcon} alt="Login" />}
            sx={{
              marginRight: "10px",
              "&:hover": { backgroundColor: "lightgray" },
            }}
          >
            {t('login')}
          </Button>
          <Button
            color="inherit"
            onClick={onRegister}
            startIcon={<img src={signInIcon} alt="Register" />}
            sx={{ "&:hover": { backgroundColor: "lightgray" } }}
          >
            {t('register')}
          </Button>
        </>
      )}
      {isLoggedIn && (
        <>
          <Button
            color="inherit"
            onClick={() => navigate("/profil")}
            startIcon={<img src={profilIcon} alt="Profil" />}
            sx={{
              marginRight: "10px",
              "&:hover": { backgroundColor: "lightgray" },
            }}
          >
            {t('profil')}
          </Button>
          {renderAdminButton()}
          <Button
            onClick={handleLogout}
            startIcon={<img src={logoutIcon} alt="Logout" />}
            sx={{
              backgroundColor: "red",
              color: "white",
              "&:hover": {
                backgroundColor: "darkred",
              },
            }}
          >
            {t('logout')}
          </Button>
        </>
      )}
    </>
  );

  const renderMobileMenu = () => (
    <List>
      <ListItem button onClick={() => navigate("/")}>
        <ListItemText primary={t('home')} />
      </ListItem>
      {!isLoggedIn && (
        <>
          <ListItem button onClick={onLogin}>
            <ListItemText primary={t('login')} />
          </ListItem>
          <ListItem button onClick={onRegister}>
            <ListItemText primary={t('register')} />
          </ListItem>
        </>
      )}
      {isLoggedIn && (
        <>
          <ListItem button onClick={() => navigate("/profil")}>
            <ListItemText primary={t('profil')} />
          </ListItem>
          {isAdmin && (
            <ListItem button onClick={() => navigate("/admin")}>
              <ListItemText primary="Admin" />
            </ListItem>
          )}
          <ListItem button onClick={handleLogout}>
            <ListItemText primary={t('logout')} />
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={clockIcon}
              alt="Clock Icon"
              style={{ marginRight: "8px" }}
            />
            <Typography variant="h6" component="div">
              Clockwise-Pro
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: isMobile ? 0 : "20px",
            }}
          >
            {isMobile ? (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              renderDesktopButtons()
            )}
          </div>
        </Toolbar>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          {renderMobileMenu()}
        </Drawer>
      </AppBar>
      <div style={{ paddingTop: "64px" }}>
        {/* Hier können Sie den Hauptinhalt Ihrer Seite platzieren */}
      </div>
    </>
  );
};

export default Navbar;
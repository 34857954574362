import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  getLanguages,
  getSelectLanguageTranslations,
} from "../../utils/apiService";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

function LanguageSelectionModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    getLanguages()
      .then((response) => {
        setLanguages(response.data);
        if (!Cookies.get("language")) {
          setModalIsOpen(true);
        }
      })
      .catch((error) => {
        console.error("Fehler beim Laden der Sprachen:", error);
      });

    getSelectLanguageTranslations()
      .then((response) => {
        const titles = Object.values(response.data);
        let currentIndex = 0;
        setDialogTitle(titles[currentIndex]);

        const intervalId = setInterval(() => {
          currentIndex = (currentIndex + 1) % titles.length;
          setDialogTitle(titles[currentIndex]);
        }, 5000); // Wechselt den Titel alle 5 Sekunden

        return () => clearInterval(intervalId);
      })
      .catch((error) => {
        console.error("Fehler beim Laden der Titelübersetzungen:", error);
      });
  }, []);

  // useEffect wird beim Laden der Komponente aufgerufen, um die verfügbaren Sprachen zu laden
  useEffect(() => {
    getLanguages()
      .then((response) => {
        // Setzt die verfügbaren Sprachen aus der Antwort des Servers
        setLanguages(response.data);
        // Öffnet das Modal, wenn noch keine Sprache im Cookie gespeichert ist
        if (!Cookies.get("language")) {
          setModalIsOpen(true);
        }
      })
      .catch((error) => {
        // Bei einem Fehler beim Laden der Sprachen wird dieser in der Konsole ausgegeben
        console.error("Fehler beim Laden der Sprachen:", error);
        // Hier könnten Sie eine Fehlerbehandlung implementieren
      });
  }, []);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Aktualisiert i18next mit der neuen Sprache
    Cookies.set("i18next", newLanguage); // Speichert die ausgewählte Sprache im Cookie
  };

  const handleAccept = () => {
    Cookies.set("language", selectedLanguage, { expires: 365 });
    setModalIsOpen(false);
  };

  const handleDecline = () => {
    setModalIsOpen(false);
  };

  const getFlagImagePath = (code) => {
    return `/img/flags/${code}.svg`; // Pfad zu den Flaggen-Dateien
  };

  return (
    <Dialog
      open={modalIsOpen}
      onClose={handleDecline}
      sx={{ "& .MuiDialog-paper": { width: "300px", height: "300px" } }} // Stil für das Dialogfenster
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Select
          value={selectedLanguage}
          onChange={handleLanguageChange}
          fullWidth
          displayEmpty
        >
          {languages.map((lang) => (
            <MenuItem
  key={lang.code}
  value={lang.code}
  style={{ 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '48px', // Festlegen einer spezifischen Höhe für das MenuItem
    width: '100%', // Das MenuItem soll die volle Breite einnehmen
    boxSizing: 'border-box', // Stellt sicher, dass padding und border im width enthalten sind
  }}
>
  <img
    src={getFlagImagePath(lang.code)}
    alt={lang.name}
    style={{
      width: '30px', // Festlegen einer spezifischen Breite für die Flagge
      height: '30px', // Festlegen einer spezifischen Höhe für die Flagge
      objectFit: 'cover', // Sorgt dafür, dass das Bild den Container ausfüllt
      borderRadius: '50%', // Kreisförmig
      marginRight: '8px', // Rechter Abstand von der Flagge
    }}
  />
  <div style={{ 
    marginLeft: 'auto', // Schiebt den Text nach rechts
    marginRight: '8px', // Rechter Abstand des Texts vom Rand
  }}> 
    {lang.name}
  </div>
</MenuItem>


          ))}
        </Select>
      </DialogContent>
      <Typography 
      variant="body2" 
      align="center" 
      sx={{ marginTop: 2, marginBottom: 2 }}
    >
      {t("coockies")}
    </Typography>
      <DialogActions>
        <Button onClick={handleDecline}>{t("Reject")}</Button>{" "}
        {/* Übersetzung für 'Ablehnen' */}
        <Button onClick={handleAccept}>{t("Accept")}</Button>{" "}
        {/* Übersetzung für 'Akzeptieren' */}
      </DialogActions>
    </Dialog>
  );
}

export default LanguageSelectionModal;
